<template>
  <div class="submit-prompt">
    <input type="file" class="file-input file-input-bordered w-full max-w-xs" accept="image/png, image/jpeg" />
    <button class="btn btn-primary" @click="submitPrompt" :disabled="loading">{{ loading ? '正在处理...' : '提交' }}</button>
    <div v-if="loading" class="loading">正在生成结果，请稍候...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="results">
      <div class="results-section">
        <h2>图片</h2>
        <div class="images">
          <img v-for="(image, index) in results.images" :src="image" :key="index" :alt="'Image ' + index"
            class="result-image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import jsonData from '@/assets/inpaint.json';

export default {
  setup() {
    const results = ref(null);
    const loading = ref(false);
    const error = ref('');

    const baseurl = "https://api.soul.moemiku.com/sora/api";
    const uploadUrl = baseurl + "/upload/image"; // 假设的上传接口URL

    var basePromptObj = jsonData;

    async function uploadImage(file, imageType = "input", overwrite = false) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('type', imageType);
      formData.append('overwrite', overwrite.toString());

      try {
        const response = await axios.post(uploadUrl, formData); // 直接传递 formData，无需手动设置 headers
        console.log('Image uploaded successfully');
        return response.data;
      } catch (error) {
        console.error('Failed to upload image:', error);
      }
    }


    const submitPrompt = async () => {
      loading.value = true;
      error.value = '';

      // 1. 获取选择的文件
      const fileInput = document.querySelector('.file-input');
      const file = fileInput.files[0];
      if (!file) {
        error.value = '请先选择一个文件。';
        loading.value = false;
        return;
      }

      // 2. 上传图片
      try {
        let respData = await uploadImage(file)

        // 3. 在上传成功后，将图片地址或标识符更新到basePromptObj
        if (respData && respData.name) {
          basePromptObj["6"].inputs.image = respData.name; // 假设返回的路径需要设置到这里

          // 4. 执行后续操作，比如调用promptAPI
          await callPromptAPI();
        } else {
          error.value = '图片上传失败。';
          loading.value = false;
        }
      } catch (err) {
        console.error('上传图片失败:', err);
        error.value = '上传图片时发生错误。';
        loading.value = false;
      }
    };

    const callPromptAPI = async () => {
      loading.value = true;
      error.value = '';
      try {
        // 使用axios发送POST请求
        const response = await axios.post(baseurl + '/prompt', JSON.stringify({
          prompt: basePromptObj
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', //不要设置content-type 为'application/json'，ComfyUI不接受application/json
          }
        });

        // 获取数据
        const data = response.data;
        if (data && data.prompt_id) {
          await pollForResult(data.prompt_id);
        } else {
          error.value = '服务器未返回有效的提示ID。';
        }
      } catch (error) {
        console.error('Error:', error);
        error.value = '请求过程中发生错误。';
      } finally {
        loading.value = false;
      }
    };

    const pollForResult = async (promptId) => {
      let attempts = 0;
      const maxAttempts = 600; // 最大尝试次数
      const interval = 2000; // 每次尝试的间隔时间（毫秒）

      while (attempts < maxAttempts) {
        try {
          const response = await fetch(baseurl + `/history/${promptId}`);
          const data = await response.json();
          if (data[promptId] == null) {
            await new Promise(resolve => setTimeout(resolve, interval));
            attempts++;
            continue;
          }
          let result = data[promptId];
          if (result && result.status && result.status.completed) {
            // 假设服务器返回的data中包含了图片和视频的相对路径image['filename'], image['subfolder'], image['type']
            // view ? filename = { filename } & subfolder={ subfolder }& type=
            let img = result.outputs['72'].images
            let imgURL = img.map(img => baseurl + '/view?filename=' + img.filename + '&subfolder=' + img.subfolder + '&type=' + img.type);
            results.value = { images: [imgURL] };
            loading.value = false;
            return;
          }
        } catch (error) {
          console.error('Error:', error);
          error.value = '轮询结果时发生错误。';
          break;
        }
        await new Promise(resolve => setTimeout(resolve, interval));
        attempts++;
      }

      if (attempts >= maxAttempts) {
        error.value = '获取结果超时，请重试。';
        loading.value = false;
      }
    };


    return { results, submitPrompt, loading, error };
  },
};
</script>

<style scoped>
.submit-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

input,
button {
  margin: 10px;
}

.loading,
.error {
  margin-top: 20px;
}

.results-section {
  margin-top: 20px;
}

.images img,
.videos video {
  max-width: 100%;
  margin: 10px 0;
}
</style>
