import { createRouter, createWebHistory } from 'vue-router';
import SubmitPrompt from './views/SubmitPrompt.vue';
import InpaintPage from './views/InpaintPage.vue';
import BigBoobPage from './views/BigBoob.vue';
import HairColored from './views/HairColored.vue';

let routes = [
    { path: '/submit', name: 'Submit', component: SubmitPrompt },
    { path: '/inpaint', name: 'Inpaint', component: InpaintPage },
    { path: '/bigboob', name: 'BigBoob', component: BigBoobPage },
    { path: '/hair', name: 'HairColored', component: HairColored },
];

if (process.env.VUE_APP_DEFAULT_PAGE != undefined) {
    routes.push({ path: '/', redirect: process.env.VUE_APP_DEFAULT_PAGE });
} else {
    routes.push({ path: '/', redirect: '/bigboob' });
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
