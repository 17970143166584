<template>
  <div>
    <div class="button-container">
      <h5>{{ $t('关注作者：') }}</h5>
      <a href="https://twitter.com/moeimiku" target="_blank">
        <img src="https://img.shields.io/twitter/follow/moeimiku?style=social" alt="Follow @moeimiku" />
      </a>
    </div>
    <div style="display: flex;" flex-direction="row">
      <a href="https://apps.apple.com/app/apple-store/id6474593002?pt=126805460&ct=sora&mt=8" target="_blank">
        <div style="display: flex; align-items: center;" flex-direction="row">
          <img alt="Sora Video Fun" src="./assets/logo.webp" width="38" height="38"
            style="margin-left: 24px; display: block; border-radius: 10px; align-self: flex-start;">
          <h6 v-if="showAll" style="color: blue">&nbsp;&nbsp;影像魔术师iOS App下载</h6>
        </div>
      </a>
      <div v-if="showAll" class=" dropdown dropdown-bottom dropdown-end"
        style="margin-left: auto; margin-right: 20px; align-self: flex-end;">
        <div tabindex="0" role="button" class="btn m-1">加群</div>
        <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
          <li><img src="./assets/wechat.png" /></li>
        </ul>
      </div>
    </div>
    <RedeemCode v-if="showAll" />
    <div v-if="showAll" role="tablist" class="tabs tabs-boxed">
      <a role="tab" class="tab" :class="{ 'tab-active': currentTab === 'Submit' }"
        @click="setCurrentTab('Submit')">视频生成</a>
      <a role="tab" class="tab" :class="{ 'tab-active': currentTab === 'Inpaint' }"
        @click="setCurrentTab('Inpaint')">超强去除人物</a>
      <a role="tab" class="tab" :class="{ 'tab-active': currentTab === 'BigBoob' }"
        @click="setCurrentTab('BigBoob')">大胸美女</a>
      <a role="tab" class="tab" :class="{ 'tab-active': currentTab === 'HairColored' }"
        @click="setCurrentTab('HairColored')">一键染发</a>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>

import RedeemCode from './components/RedeemCode.vue';
import { reactive, provide } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'App',
  components: {
    RedeemCode,
  },
  setup() {
    const { t } = useI18n();
    if (process.env.VUE_APP_LANG === "en") {
      document.title = t("appTitle");
      document.querySelector("meta[name='description']").setAttribute("content", t("description"));
    }
    const redeem = reactive({
      showRedeemAlert: false
    });
    provide('redeem', redeem);
  },
  data() {
    return {
      showAll: process.env.VUE_APP_DISABLE_OTHER !== 'true'
    }
  },
  computed: {
    currentTab() {
      return this.$route.name;
    }
  },
  methods: {
    setCurrentTab(tab) {
      if (tab === 'Submit' || tab === 'Inpaint') {
        // 禁止跳转并弹出提示
        alert('过于火爆，暂停其他功能使用，有需要请关注并私信推主');
      } else {
        // 允许跳转到"大胸美女"选项卡
        this.$router.push({ name: tab });
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    height: 100vh;
  }
    .button-container {
      display: flex;
      position: absolute;
      right: 160px;
      top: 20px;
      flex-direction: row;
      flex-wrap: nowrap;
    }
</style>
