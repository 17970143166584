<template>
    <div v-if="codeSuccess" role="alert" class="alert alert-success">
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>太棒了~ 恭喜兑换成功!! 感谢支持!!</span>
    </div>
    <div class="p-4">
        <!-- 触发模态窗口的按钮 -->
        <button @click="isModalOpen = true" class="btn btn-primary btn-sm fixed top-4 right-4">输入兑换码</button>
        <!-- 模态窗口 -->
        <div v-if="isModalOpen" class="modal modal-open">
            <div class="modal-box">
                <h3 class="font-bold text-lg">输入兑换码</h3>
                <input type="text" placeholder="请输入兑换码" class="input input-bordered w-full max-w-xs my-4"
                    v-model="redeemCode" />
                <div class="modal-action">
                    <button @click="submitRedeemCode" class="btn btn-primary">兑换</button>
                    <button @click="isModalOpen = false" class="btn">关闭</button>
                    <button @click="howToGetCode" class="btn">如何获取?</button>
                </div>
            </div>
        </div>
    </div>
    <!-- 兑换提示 -->
    <div v-if="redeemState.showRedeemAlert" class="alert alert-error">
        <div class="modal-box">
            <h3 class="font-bold text-lg">由于使用量过大, 请先关注我们的Twitter账号并转发或评论置顶推文。完成后，您将收到兑换码以继续使用。谢谢~</h3>

            <div class="modal-action">
                <button @click="getCode" class="btn btn-primary">关注获取兑换码</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject } from 'vue';

export default {
    setup() {
        const isModalOpen = ref(false);
        const redeemCode = ref('');
        const codeSuccess = ref(false);

        const redeemState = inject('redeem');

        let allowCodes = ['4SW3M6O0YX',
            'WF87WWW8SE',
            'UPA45EDB20',
            '4REL5RW12C',
            'ACUT2LPLVZ',
            'GSZJQCN93H',
            'XGA84N81M7',
            'POFZNWLKC4',
            'LTT1VUQ7G7',
            'Z7Y3UL5W30',
            'Z9NYDONQ3P',
            'AOYDPMCX8O',
            'ONSIF5AJMP',
            'U5M4YMKHL2',
            'JE09AEH486',
            'IUJUS074BK',
            'J4XC9ZVLVC',
            'NE6NBI6Y0W',
            'PY6PHO0PWO',
            'ELPQDK1P06',
            'LZH20NGHO3',
            'DA5G2V81OA',
            '9DZM932W9C',
            'CFIQNGRNRB',
            'RZHI7TNRWD',
            'GUYFN3V0EH',
            '39AH9NQ2R8',
            'VDHCY6LTZO',
            'UYIJ7D8QJ3',
            'ZTT86WWK1E',
            'TQFPQAPMBZ',
            'R16M54P3NT',
            'CLGM2DQMVV',
            'GIU8555227',
            'QNU0ZYUC58',
            '4ZIB760GYI',
            '0Q5MKU8NAV',
            '76Z90U13IH',
            'AHFRASXXKQ',
            '2307ENXLJU',
            '47T6GI1T67',
            'RZ1WPCYEA9',
            'AJFZBAY8GN',
            '0G7XWU84EL',
            '49U0I8PQVM',
            'NQ0TZXN7LM',
            'DT4HZ9HFZV',
            '1N4R7RJJAY',
            '1GW91MXMU1',
            '6XZFXOHKVE',
            'VT2YU66TOP',
            'UHTXGFU4OS',
            'JQXDR68AMI',
            'QISO6M76YF',
            '4IMFAH2RKZ',
            'BLKD7KNNR9',
            'OB6HU4GLY2',
            '1U44EZV45B',
            'YBO57FHV96',
            'FB1EB1TSTF',
            'QY58NVAA3K',
            '93IIE5JDNZ',
            'FQHK9WOXAR',
            '8OQPZ6DGE4',
            'ZAYLSAIQCT',
            'PJ2ZLJ08TM',
            'R4OMJHU3MO',
            'RM9QNYZKKW',
            'N1R2BPSIVX',
            'S6797W1PMH',
            'XRKDMUJ5OV',
            '1T7PILM04S',
            'BN32T3QUSN',
            'I24P3GF8HC',
            'G60FX5S28R',
            'P6UBJP1AV4',
            '2LXBJCKKFX',
            'QJ20TVBMW2',
            'EA96T8F0KO',
            '73PSMP6ATU',
            'M589WAO4XF',
            'YZR7ZHJ9UA',
            '0KJQCC23FY',
            '1SYE4OXN0A',
            'MYW1PQ5S5G',
            'C97PJAUSRO',
            'XEOH6F1YGO',
            '06MNTM5USF',
            'YE00BBM7WP',
            'IX08FVUUER',
            'NB4EE4J1Z0',
            '4N45411W6A',
            'XVKN6WGOBE',
            'YYVJ9HU0RJ',
            '8Q9E88NOYG',
            'DVPX7LJIY1',
            'ZLIEPIZ3S2',
            'Y2QQGA72GH',
            'FCIPE7UZ6R',
            'GP4TGVUB2J'];

        const submitRedeemCode = async () => {
            // 这里添加提交兑换码的逻辑
            console.log("提交的兑换码：", redeemCode.value);
            // 假设这里是一个API调用验证兑换码，以下是模拟的结果处理
            if (!allowCodes.includes(redeemCode.value)) {
                // 兑换码不正确
                alert("兑换码不正确");
                return;
            }
            codeSuccess.value = true; // 兑换成功
            // 3s后消失
            setTimeout(() => {
                codeSuccess.value = false;
            }, 3000);

            isModalOpen.value = false; // 提交后关闭模态窗口
            redeemCode.value = ''; // 清空兑换码输入
            redeemState.showRedeemAlert = false; // 需要兑换

            // 保存兑换状态到localStorage
            localStorage.setItem('redeemed', true);
        };

        const getCode = () => {
            // 引导用户获取兑换码，例如打开一个网页或显示进一步的指示
            console.log("打开获取兑换码的说明页面");
            redeemState.showRedeemAlert = false; // 需要兑换
            // 这里可以使用 window.open(url) 打开获取兑换码的页面
            window.open("https://twitter.com/moeimiku", '_blank');
            isModalOpen.value = true;
        };

        const howToGetCode = () => {
            isModalOpen.value = false;
            redeemState.showRedeemAlert = true;
        };

        return {
            redeemCode,
            isModalOpen,
            redeemState,
            codeSuccess,
            submitRedeemCode,
            getCode,
            howToGetCode
        };
    },
};
</script>

<style scoped>
.redeem-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input,
.button,
.alert {
    margin-top: 10px;
}

.success {
    color: green;
}

.error {
    color: red;
}

.alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>
