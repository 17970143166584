<template>
    <div class="image-process">
        <!-- 图片上传 -->
        <input type="file" class="file-input file-input-bordered w-full max-w-xs"
            accept="image/png, image/jpeg, image/webp" />
        <div flex-direction="row">
            <button class="btn btn-primary" @click="submitPrompt" :disabled="loading">{{ loading ? processingText :
                submitText }}</button>
            <a v-if="results?.image" :href="results.image" download="image.jpg">
                <button class="btn btn-accent">{{ saveText }}</button>
            </a>
        </div>
        <div v-if="loading" style="flex-direction: row;">
            <div class="loading" />
            <div>{{ loadingText }}</div>
        </div>
        <!-- 显示错误信息 -->
        <div v-if="error" class="error">{{ error }}</div>
        <!-- 图片对比滑块，使用传入的默认图片作为初始值 -->
        <ImgComparisonSlider class="results-section">
            <!-- eslint-disable -->
            <template v-if="results">
                <img slot="first" :src="results.upload" />
                <img slot="second" :src="results.image" />
            </template>
            <template v-else>
                <img slot="first" :src="defaultImages.first" />
                <img slot="second" :src="defaultImages.second" />
            </template>
            <!-- eslint-enable -->
        </ImgComparisonSlider>
    </div>
</template>

<script>
import { inject, ref } from 'vue';
import axios from 'axios';
import { ImgComparisonSlider } from '@img-comparison-slider/vue';
import { useI18n } from 'vue-i18n';

export default {
    components: {
        ImgComparisonSlider,
    },
    props: {
        comfyUIPromptJsonObject: {
            type: Object,
            required: true
        },
        inputNode: {
            type: String,
            required: true,
            default: "input"
        },
        outputNode: {
            type: String,
            required: true,
            default: "output"
        },
        defaultImages: {
            type: Object,
            required: true,
            default: () => ({ first: 'first', second: 'second' })
        },
        postSendPrompt: {
            type: Function,
            required: false
        },
    },
    setup(props) {
        const { t } = useI18n({ useScope: 'global' })

        const baseurl = "https://api.soul.moemiku.com/sora/api";
        const uploadUrl = baseurl + "/upload/image"; // 假设的上传接口URL

        const results = ref(null);
        const loading = ref(false);
        const queuePosition = ref(-1); // 队列位置, 正在排队 0代表正在处理
        const error = ref('');
        const size_value = ref(2);
        const loadingText = ref(t('正在处理...'));

        
        

        // 中文文本管理
        const processingText = t('正在处理...');
        const submitText = t('提交');
        const saveText = t('保存');
        const selectPhotoText = t('请先选择一张照片。');
        const uploadFailedText = t('图片上传失败。');
        const processingErrorText = t('上传图片时发生错误。');
        const invalidPromptIdText = t('服务器未返回有效的提示ID。');
        const queueStateErrorText = t('轮询队列状态时发生错误。');
        const resultPollingErrorText = t('轮询结果时发生错误。');
        const timeoutErrorText = t('获取结果超时，请重试。');

        // 排队文本拼接方法的国际化版本
        const formatQueueText = (position) => {
            if (position === 0) {
                return `${processingText} (${t('预估10秒内完成')})`;
            } else {
                const estimatedTime = position * 10;
                if (position >= 6) {
                    return `${t('排队人数较多')}(${position}) (${t('预估')} ${estimatedTime} ${t('秒内开始')})`;
                } else {
                    return `${t('排队中')}(${position})... (${t('预估')} ${estimatedTime} ${t('秒内开始')})`;
                }
            }
        };

        async function uploadImage(file, imageType = "input", overwrite = false) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('type', imageType);
            formData.append('overwrite', overwrite.toString());

            if (file) {
                // 创建本地URL
                results.value = {
                    upload: URL.createObjectURL(file),
                }
            }

            try {
                const response = await axios.post(uploadUrl, formData); // 直接传递 formData，无需手动设置 headers
                console.log('Image uploaded successfully');
                return response.data;
            } catch (error) {
                console.error('Failed to upload image:', error);
            }
        }

        const redeem = inject('redeem');

        const submitPrompt = async () => {
            // 使用次数，从localStorage中获取
            let used = localStorage.getItem('used');
            // 是否兑换了
            let redeemed = localStorage.getItem('redeemed');
            if (redeemed || used < 1) {
                // 如果用户已经完成互动，执行所需功能
                submitPromptInternal(false);
            } else {
                // 如果用户尚未完成互动，弹出提示并引导他们到Twitter
                redeem.showRedeemAlert = true;
            }
        }

        const submitPromptInternal = async () => {
            queuePosition.value = -1;
            loadingText.value = processingText;
            loading.value = true;
            error.value = '';

            // 1. 获取选择的文件
            const fileInput = document.querySelector('.file-input');
            const file = fileInput.files[0];
            if (!file) {
                error.value = selectPhotoText;
                loading.value = false;
                return;
            }

            // 2. 上传图片
            try {
                let respData = await uploadImage(file)
                let basePromptObj = JSON.parse(JSON.stringify(props.comfyUIPromptJsonObject));
                // 3. 在上传成功后，将图片地址或标识符更新到basePromptObj
                if (respData && respData.name) {
                    basePromptObj[props.inputNode].inputs.image = respData.name; // 假设返回的路径需要设置到这里
                    if (props.postSendPrompt) {
                        props.postSendPrompt(basePromptObj, respData.name); // 发送请求之前的处理
                    }
                    console.log(size_value.value)
                    // 4. 执行后续操作，比如调用promptAPI
                    await callPromptAPI(basePromptObj);
                } else {
                    error.value = uploadFailedText;
                    loading.value = false;
                }
            } catch (err) {
                console.error('上传图片失败:', err);
                error.value = processingErrorText;
                loading.value = false;
            }
        };

        const callPromptAPI = async (promptObj) => {
            loading.value = true;
            error.value = '';
            try {
                // 使用axios发送POST请求
                const response = await axios.post(baseurl + '/prompt', JSON.stringify({
                    prompt: promptObj
                }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', //不要设置content-type 为'application/json'，ComfyUI不接受application/json
                    }
                });

                // 获取数据
                const data = response.data;
                if (data && data.prompt_id) {
                    await pollForResult(data.prompt_id);
                } else {
                    error.value = invalidPromptIdText;
                }
            } catch (error) {
                console.error('Error:', error);
                error.value = queueStateErrorText;
            } finally {
                loading.value = false;
            }
        };


        const pollForQueueState = async (promptId) => {
            try {
                const response = await fetch(baseurl + `/queue`);
                const data = await response.json();
                console.log('Queue state:', data);
                console.log('Prompt ID:', promptId);
                // 先从 queue_running 中查找是否有当前的 promptId
                let queue_running = data.queue_running;
                for (let i = 0; i < queue_running.length; i++) {
                    if (queue_running[i][1] == promptId) {
                        console.log('Prompt ID:', promptId, 'is running');
                        queuePosition.value = 0
                        loadingText.value = formatQueueText(0);
                        console.log(loadingText.value)
                        return;
                    }
                }
                // 如果没有找到，再从 queue_pending 中查找是否有当前的 promptId
                let queue_pending = data.queue_pending;
                for (let i = 0; i < queue_pending.length; i++) {
                    if (queue_pending[i][1] == promptId) {
                        console.log('Prompt ID:', promptId, 'is pending');
                        queuePosition.value = i + 1;
                        loadingText.value = formatQueueText(i + 1);
                        console.log(loadingText.value)
                        return;
                    }
                }
            } catch (error) {
                console.error('Error:', error);
                error.value = queueStateErrorText;
            }
        };

        const pollForResult = async (promptId) => {
            let attempts = 0;
            const maxAttempts = 1200; // 最大尝试次数
            const interval = 2000; // 每次尝试的间隔时间（毫秒）

            while (attempts < maxAttempts) {
                try {
                    if (attempts % 5 === 0) {
                        // 十秒更新一次队列状态
                        pollForQueueState(promptId);
                    }
                    const response = await fetch(baseurl + `/history/${promptId}`);
                    const data = await response.json();
                    if (data[promptId] == null) {
                        await new Promise(resolve => setTimeout(resolve, interval));
                        attempts++;
                        continue;
                    }
                    let result = data[promptId];
                    if (result && result.status && result.status.completed) {
                        // 假设服务器返回的data中包含了图片和视频的相对路径image['filename'], image['subfolder'], image['type']
                        // view ? filename = { filename } & subfolder={ subfolder }& type=
                        let img = result.outputs[props.outputNode].images
                        let imgURL = img.map(img => baseurl + '/view?filename=' + img.filename + '&subfolder=' + img.subfolder + '&type=' + img.type);
                        results.value = {
                            upload: results.value.upload,
                            image: imgURL
                        };
                        loading.value = false;
                        // 保存使用次数到localStorage
                        let used = localStorage.getItem('used');
                        localStorage.setItem('used', used ? used + 1 : 1);
                        return;
                    }
                } catch (error) {
                    console.error('Error:', error);
                    error.value = resultPollingErrorText;
                    break;
                }
                await new Promise(resolve => setTimeout(resolve, interval));
                attempts++;
            }

            if (attempts >= maxAttempts) {
                error.value = timeoutErrorText;
                loading.value = false;
            }
        };

        return {
            results,
            loading,
            error,
            loadingText,
            submitPrompt,
            processingText,
            submitText,
            saveText,
            selectPhotoText,
            uploadFailedText,
            processingErrorText,
            invalidPromptIdText,
            queueStateErrorText,
            resultPollingErrorText,
            timeoutErrorText,
            formatQueueText
        }
    },
};
</script>

<style scoped>
.image-process {
    margin: 0;
}

button {
    margin: 10px;
}

.error {
    color: red;
}

img-comparison-slider img {
    max-width: 100%;
    height: calc(100vh - 310px);
    display: block;
    margin: auto;
    object-fit: contain;
}
</style>
