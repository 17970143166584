import { createI18n } from 'vue-i18n'

const messages = {
    en: {
        "关注作者：": "Follow the author：",
        "红色": "Red",
        "酒红色": "Burgundy",
        "金发": "Blond",
        "银发": "Silver",
        "紫色": "Purple",
        "紫色（浅）": "Light Purple",
        "粉色": "Pink",
        "粉色（浅）": "Light Pink",
        "蓝色": "Blue",
        "蓝色（浅）": "Light Blue",
        "绿色": "Green",
        "黄色": "Yellow",
        "漂染（更鲜艳)": "Bleached (Brighter)",
        "正在处理...": "Processing...",
        "提交": "Submit",
        "保存": "Save",
        "请先选择一张照片。": "Please select a photo first.",
        "图片上传失败。": "Image upload failed.",
        "上传图片时发生错误。": "An error occurred while uploading the image.",
        "服务器未返回有效的提示ID。": "The server did not return a valid prompt ID.",
        "轮询队列状态时发生错误。": "An error occurred while polling the queue status.",
        "轮询结果时发生错误。": "An error occurred while polling the results.",
        "获取结果超时，请重试。": "Timeout while fetching results, please try again.",
        "预估10秒内完成": "Estimated completion within 10 seconds",
        "排队人数较多": "A large number of people in queue",
        "预估": "Estimated",
        "秒内开始": "seconds to start",
        "排队中": "Queuing",
        "appTitle": "Image Magician",
        "description": "Image Magician is a website that provides high-quality image processing services. Offering features like one-click enhancement, AI enhancement, and more AI features~"
    },
}

const i18n = createI18n({
    // 设置默认语言
    locale: process.env.VUE_APP_LANG === "en" ? "en" : 'zh',
    messages,
    legacy: false,
})

export default i18n
