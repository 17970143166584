<template>
  <div class="submit-prompt">
    <input v-model="prompt" type="text" placeholder="输入内容描述" class="input input-bordered w-full max-w-xs" />

    <button class="btn btn-primary" @click="submitPrompt" :disabled="loading">{{ loading ? '正在处理...' : '提交' }}</button>
    <div v-if="loading" class="loading">正在生成结果，请稍候...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="results">
      <div class="results-section">
        <h2>视频</h2>
        <div class="videos">
          <video v-for="(video, index) in results.videos" :src="video" :key="index" controls
            class="result-video"></video>
        </div>
        <h2>图片</h2>
        <div class="images">
          <img v-for="(image, index) in results.images" :src="image" :key="index" :alt="'Image ' + index"
            class="result-image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const prompt = ref('');
        const results = ref(null);
        const loading = ref(false);
        const error = ref('');

        const baseurl = "https://api.soul.moemiku.com/sora/api"
        // const baseurl = "https://imiku.iok.la:8188"

        const basePrompt = `
        {
  "8": {
    "inputs": {
      "samples": [
        "38",
        0
      ],
      "vae": [
        "15",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE解码"
    }
  },
  "12": {
    "inputs": {
      "width": 576,
      "height": 1024,
      "video_frames": 25,
      "motion_bucket_id": 55,
      "fps": 6,
      "augmentation_level": 0.05,
      "clip_vision": [
        "15",
        1
      ],
      "init_image": [
        "46",
        0
      ],
      "vae": [
        "15",
        2
      ]
    },
    "class_type": "SVD_img2vid_Conditioning",
    "_meta": {
      "title": "SVD_图像到视频_条件"
    }
  },
  "14": {
    "inputs": {
      "min_cfg": 2,
      "model": [
        "15",
        0
      ]
    },
    "class_type": "VideoLinearCFGGuidance",
    "_meta": {
      "title": "线性CFG引导"
    }
  },
  "15": {
    "inputs": {
      "ckpt_name": "svd_xt_1_1.safetensors"
    },
    "class_type": "ImageOnlyCheckpointLoader",
    "_meta": {
      "title": "Checkpoint加载器(仅图像)"
    }
  },
  "26": {
    "inputs": {
      "frame_rate": 20,
      "loop_count": 0,
      "filename_prefix": "SVD",
      "format": "video/h264-mp4",
      "pix_fmt": "yuv420p",
      "crf": 20,
      "save_metadata": true,
      "pingpong": false,
      "save_output": true,
      "images": [
        "30",
        0
      ]
    },
    "class_type": "VHS_VideoCombine",
    "_meta": {
      "title": "合并为视频"
    }
  },
  "30": {
    "inputs": {
      "ckpt_name": "sudo_rife4_269.662_testV1_scale1.pth",
      "clear_cache_after_n_frames": 10,
      "multiplier": 2,
      "fast_mode": true,
      "ensemble": true,
      "scale_factor": 1,
      "frames": [
        "8",
        0
      ]
    },
    "class_type": "RIFE VFI",
    "_meta": {
      "title": "RIFE VFI (recommend rife47 and rife49)"
    }
  },
  "36": {
    "inputs": {
      "b1": 1.3,
      "b2": 1.4,
      "s1": 0.9,
      "s2": 0.2,
      "model": [
        "14",
        0
      ]
    },
    "class_type": "FreeU_V2",
    "_meta": {
      "title": "FreeU_V2模型重加权"
    }
  },
  "38": {
    "inputs": {
      "seed": [
        "40",
        0
      ],
      "steps": 12,
      "cfg": 2,
      "sampler_name": "euler",
      "scheduler": "karras",
      "denoise": 1,
      "model": [
        "36",
        0
      ],
      "positive": [
        "12",
        0
      ],
      "negative": [
        "12",
        1
      ],
      "latent_image": [
        "12",
        2
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "K采样器"
    }
  },
  "40": {
    "inputs": {
      "seed": 301447066168276
    },
    "class_type": "Seed (rgthree)",
    "_meta": {
      "title": "随机种"
    }
  },
  "41": {
    "inputs": {
      "seed": 195303404928420,
      "steps": 6,
      "cfg": 2,
      "sampler_name": "dpmpp_sde",
      "scheduler": "karras",
      "denoise": 1,
      "model": [
        "42",
        0
      ],
      "positive": [
        "44",
        0
      ],
      "negative": [
        "45",
        0
      ],
      "latent_image": [
        "43",
        0
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "K采样器"
    }
  },
  "42": {
    "inputs": {
      "ckpt_name": "dreamshaperXL_v2TurboDpmppSDE.safetensors"
    },
    "class_type": "CheckpointLoaderSimple",
    "_meta": {
      "title": "Checkpoint加载器(简易)"
    }
  },
  "43": {
    "inputs": {
      "width": 576,
      "height": 1024,
      "batch_size": 1
    },
    "class_type": "EmptyLatentImage",
    "_meta": {
      "title": "空Latent"
    }
  },
  "44": {
    "inputs": {
      "text": "An evocative portrayal of the sea's fury, with towering white waves crashing higher than the Wanguan Pavilion, a structure symbolizing human endeavor and architectural grace beside the tumultuous ocean. The scene contrasts the untamed force of the waves with the serene beauty of the pavilion, highlighting the ephemeral moment when nature's wildness overshadows human achievements. The sunlight filtering through the storm clouds illuminates the frothy crests of the waves, casting a radiant glow that enhances the scene's dramatic tension. This image symbolizes the delicate balance between humanity and the powerful forces of nature, showcasing both the vulnerability and resilience of human creations in the face of nature's might.",
      "clip": [
        "42",
        1
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP文本编码器"
    }
  },
  "45": {
    "inputs": {
      "text": "text, watermark, low quality, medium quality, blurry, censored, wrinkles, deformed, mutated text, watermark, low quality, medium quality, blurry, censored, wrinkles, deformed, mutated, BadDream-SDXL, FastNegativeV2-SDXL",
      "clip": [
        "42",
        1
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP文本编码器"
    }
  },
  "46": {
    "inputs": {
      "samples": [
        "41",
        0
      ],
      "vae": [
        "42",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE解码"
    }
  },
  "47": {
    "inputs": {
      "filename_prefix": "ComfyUI",
      "images": [
        "46",
        0
      ]
    },
    "class_type": "SaveImage",
    "_meta": {
      "title": "保存图像"
    }
  }
}
        `
        var basePromptObj = JSON.parse(basePrompt)
        console.log(basePromptObj);

        const submitPrompt = async () => {
            loading.value = true;
            error.value = '';
            try {
                // 先调用 https://api.soul.moemiku.com/sora/getDrawWorld?input=%E6%B8%85%E6%99%A8%E5%B0%8F%E8%B7%AF 获取提示词

                const goodPromptResponse = await fetch('https://api.soul.moemiku.com/sora/getDrawWorld?input=' + prompt.value)
                const goodPromptData = await goodPromptResponse.json()
                let goodPrompt = goodPromptData.result
                alert("开始绘制如下内容，请不要刷新页面，在当前页面等待:\n" + goodPrompt)
                
                // 填充到basePromptObj中
                basePromptObj["44"].inputs.text = goodPrompt
                

                // 使用axios发送POST请求
                const response = await axios.post(baseurl + '/prompt', JSON.stringify({
                    prompt: basePromptObj
                }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', //不要设置content-type 为'application/json'，ComfyUI不接受application/json
                    }
                });

                // 获取数据
                const data = response.data;
                if (data && data.prompt_id) {
                    await pollForResult(data.prompt_id);
                } else {
                    error.value = '服务器未返回有效的提示ID。';
                }
            } catch (error) {
                console.error('Error:', error);
                error.value = '请求过程中发生错误。';
            } finally {
                loading.value = false;
            }
        };

        const pollForResult = async (promptId) => {
            let attempts = 0;
            const maxAttempts = 600; // 最大尝试次数
            const interval = 2000; // 每次尝试的间隔时间（毫秒）

            while (attempts < maxAttempts) {
                try {
                    const response = await fetch(baseurl + `/history/${promptId}`);
                    const data = await response.json();
                    if (data[promptId] == null) {
                        await new Promise(resolve => setTimeout(resolve, interval));
                        attempts++;
                        continue;
                    }
                    let result = data[promptId];
                    if (result && result.status && result.status.completed) {
                        // 假设服务器返回的data中包含了图片和视频的相对路径image['filename'], image['subfolder'], image['type']
                        // view ? filename = { filename } & subfolder={ subfolder }& type=
                        let img = result.outputs['47'].images
                        let imgURL = img.map(img => baseurl + '/view?filename=' + img.filename + '&subfolder=' + img.subfolder + '&type=' + img.type);
                        let video = result.outputs['26'].gifs
                        let videoURL = video.map(vid => baseurl + '/view?filename=' + vid.filename + '&subfolder=' + vid.subfolder + '&type=' + vid.type);
                        results.value = { images: [imgURL], videos: [videoURL] };
                        loading.value = false;
                        return;
                    }
                } catch (error) {
                    console.error('Error:', error);
                    error.value = '轮询结果时发生错误。';
                    break;
                }
                await new Promise(resolve => setTimeout(resolve, interval));
                attempts++;
            }

            if (attempts >= maxAttempts) {
                error.value = '获取结果超时，请重试。';
                loading.value = false;
            }
        };

        return { prompt, results, submitPrompt, loading, error };
    },
};
</script>

<style scoped>
.submit-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

input,
button {
    margin: 10px;
}

.loading,
.error {
    margin-top: 20px;
}

.results-section {
    margin-top: 20px;
}

.images img,
.videos video {
    max-width: 100%;
    margin: 10px 0;
}
</style>
