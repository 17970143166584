<template>
  <div class="big-boob">
    <input type="range" min="0" max="3" v-model="size_value" class="range" step="1" style="max-width: 400px;" />
    <div class="w-full flex justify-between text-xs px-2" style="max-width: 400px;">
      <span>小</span>
      <span>中</span>
      <span>大</span>
      <span>巨(*)</span>
    </div>
    <input type="file" class="file-input file-input-bordered w-full max-w-xs" accept="image/png, image/jpeg" />
    <div flex-direction="row">
      <button class="btn btn-primary" @click="submitPrompt" :disabled="loading">{{ loading ? '正在处理...' : '提交'
        }}</button>
      <a v-if="results?.image" :href="results.image" download="image.jpg">
        <button class="btn btn-accent">保存</button>
      </a>
      <button v-if="results?.image" @click="submitPromptSuper" class="btn btn-secondary">强力重试</button>

    </div>
    <div v-if="loading" style="flex-direction: row;">
      <div class="loading" />
      <div>{{ loadingText }}</div>
    </div>
    <div v-if="error" class="error">{{ error }}</div>
    <ImgComparisonSlider class="results-section">
      <!-- eslint-disable -->
      <template v-if="results">
        <img slot="first" :src="results.upload" />
        <img slot="second" :src="results.image" />
      </template>
      <template v-else>
        <img slot="first" src="../assets/big_breast/origin.png" />
        <img slot="second" src="../assets/big_breast/result.png" />
      </template>
      <!-- eslint-enable -->
    </ImgComparisonSlider>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import axios from 'axios';
import jsonData from '@/assets/big_breast/big_breast.json';
import { ImgComparisonSlider } from '@img-comparison-slider/vue';
export default {
  components: {
    ImgComparisonSlider,
  },
  methods: {
    
  },
  setup() {
    const baseurl = "https://api.soul.moemiku.com/sora/api";
    const uploadUrl = baseurl + "/upload/image"; // 假设的上传接口URL

    const results = ref(null);
    const loading = ref(false);
    const queuePosition = ref(-1); // 队列位置, 正在排队 0代表正在处理
    const error = ref('');
    
    const size_value = ref(2);
    const loadingText = ref('正在处理...');

    const redeem = inject('redeem');

    async function uploadImage(file, imageType = "input", overwrite = false) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('type', imageType);
      formData.append('overwrite', overwrite.toString());

      if (file) {
        // 创建本地URL
        results.value = {
          upload: URL.createObjectURL(file),
        }
      }

      try {
        const response = await axios.post(uploadUrl, formData); // 直接传递 formData，无需手动设置 headers
        console.log('Image uploaded successfully');
        return response.data;
      } catch (error) {
        console.error('Failed to upload image:', error);
      }
    }
    const submitPromptSuper = async () => {
      submitPromptInternal(true);
    }

    const submitPrompt = async () => {
      // 使用次数，从localStorage中获取
      let used = localStorage.getItem('used');
      // 是否兑换了
      let redeemed = localStorage.getItem('redeemed');
      if (redeemed || used < 1) {
        // 如果用户已经完成互动，执行所需功能
        submitPromptInternal(false);
      } else {
        // 如果用户尚未完成互动，弹出提示并引导他们到Twitter
        redeem.showRedeemAlert = true;
      }
    }

    const submitPromptInternal = async (force) => {
      queuePosition.value = -1;
      loadingText.value = '正在处理...';
      loading.value = true;
      error.value = '';

      // 1. 获取选择的文件
      const fileInput = document.querySelector('.file-input');
      const file = fileInput.files[0];
      if (!file) {
        error.value = '请先选择一个文件。';
        loading.value = false;
        return;
      }

      // 2. 上传图片
      try {
        let respData = await uploadImage(file)
        let basePromptObj = JSON.parse(JSON.stringify(jsonData));

        // 3. 在上传成功后，将图片地址或标识符更新到basePromptObj
        if (respData && respData.name) {
          basePromptObj["6"].inputs.image = respData.name; // 假设返回的路径需要设置到这里
          console.log(force)
          console.log(size_value.value)
          if (!force) {
            basePromptObj["17"].inputs.mask_optional = ["113", 0];
          }
          if (size_value.value != 3) {
            // 0: small, 1: medium, 2: large, 3: huge
            // 非巨乳 去掉lora
            basePromptObj["2"].inputs.clip = ["1", 1];
            basePromptObj["3"].inputs.clip = ["1", 1];
            basePromptObj["7"].inputs.model = ["1", 0];
          }
          if (size_value.value == 0) {
            basePromptObj["2"].inputs.text = "small_breasts, "
          } else if (size_value.value == 1) {
            basePromptObj["2"].inputs.text = "medium_breasts, "
          }


          // 4. 执行后续操作，比如调用promptAPI
          await callPromptAPI(basePromptObj);
        } else {
          error.value = '图片上传失败。';
          loading.value = false;
        }
      } catch (err) {
        console.error('上传图片失败:', err);
        error.value = '上传图片时发生错误。';
        loading.value = false;
      }
    };

    const callPromptAPI = async (promptObj) => {
      loading.value = true;
      error.value = '';
      try {
        // 使用axios发送POST请求
        const response = await axios.post(baseurl + '/prompt', JSON.stringify({
          prompt: promptObj
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', //不要设置content-type 为'application/json'，ComfyUI不接受application/json
          }
        });

        // 获取数据
        const data = response.data;
        if (data && data.prompt_id) {
          await pollForResult(data.prompt_id);
        } else {
          error.value = '服务器未返回有效的提示ID。';
        }
      } catch (error) {
        console.error('Error:', error);
        error.value = '请求过程中发生错误。';
      } finally {
        loading.value = false;
      }
    };

    
    const pollForQueueState = async (promptId) => {
      try {
        const response = await fetch(baseurl + `/queue`);
        const data = await response.json();
        console.log('Queue state:', data);
        console.log('Prompt ID:', promptId);
        // 先从 queue_running 中查找是否有当前的 promptId
        let queue_running = data.queue_running;
        for (let i = 0; i < queue_running.length; i++) {
          if (queue_running[i][1] == promptId) {
            console.log('Prompt ID:', promptId, 'is running');
            queuePosition.value = 0
            loadingText.value = '正在处理...(预估10秒内完成)';
            console.log(loadingText.value)
            return;
          }
        }
        // 如果没有找到，再从 queue_pending 中查找是否有当前的 promptId
        let queue_pending = data.queue_pending;
        for (let i = 0; i < queue_pending.length; i++) {
          if (queue_pending[i][1] == promptId) {
            console.log('Prompt ID:', promptId, 'is pending');
            queuePosition.value = i + 1;
            const estimatedTime = queuePosition.value * 10;
            if (queuePosition.value >= 6) {
              loadingText.value = `排队人数较多(${queuePosition.value})(预估${estimatedTime}秒内开始)`;
            } else {
              loadingText.value = `排队中(${queuePosition.value})...(预估${estimatedTime}秒内开始)`;
            }
            console.log(loadingText.value)
            return;
          }
        }
      } catch (error) {
        console.error('Error:', error);
        error.value = '轮询队列状态时发生错误。';
      }
    };

    const pollForResult = async (promptId) => {
      let attempts = 0;
      const maxAttempts = 1200; // 最大尝试次数
      const interval = 2000; // 每次尝试的间隔时间（毫秒）

      while (attempts < maxAttempts) {
        try {
          if (attempts % 5 === 0) {
            // 十秒更新一次队列状态
            pollForQueueState(promptId);
          }
          const response = await fetch(baseurl + `/history/${promptId}`);
          const data = await response.json();
          if (data[promptId] == null) {
            await new Promise(resolve => setTimeout(resolve, interval));
            attempts++;
            continue;
          }
          let result = data[promptId];
          if (result && result.status && result.status.completed) {
            // 假设服务器返回的data中包含了图片和视频的相对路径image['filename'], image['subfolder'], image['type']
            // view ? filename = { filename } & subfolder={ subfolder }& type=
            let img = result.outputs['72'].images
            let imgURL = img.map(img => baseurl + '/view?filename=' + img.filename + '&subfolder=' + img.subfolder + '&type=' + img.type);
            results.value = {
              upload: results.value.upload,
              image: imgURL
            };
            loading.value = false;
            // 保存使用次数到localStorage
            let used = localStorage.getItem('used');
            localStorage.setItem('used', used ? used + 1 : 1);
            return;
          }
        } catch (error) {
          console.error('Error:', error);
          error.value = '轮询结果时发生错误。';
          break;
        }
        await new Promise(resolve => setTimeout(resolve, interval));
        attempts++;
      }

      if (attempts >= maxAttempts) {
        error.value = '获取结果超时，请重试。';
        loading.value = false;
      }
    };


    
    return {
      results, submitPrompt, submitPromptSuper, loading, error, size_value, loadingText
    };
  },
};
</script>

<style scoped>
.big-boob {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: calc(100vh - 100px);
}

.results-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

input,
button {
  margin: 10px;
}

.loading,
.error {
  margin-top: 0px;
}

.images img,
.videos video {
  max-width: 100%;
  margin: 10px 0;
}

img-comparison-slider {
  width: 100%;
  /* 或者其他根据你的页面设计指定的宽度 */
  height: auto;
  /* 根据图片高度自动调整 */
}

img-comparison-slider img {
  max-width: 100%;
  height: calc(100vh - 310px);
  display: block;
  margin: auto;
  object-fit: contain;
}
</style>
